// eslint-disable-next-line jira/import-whitelist
import { metrics } from '@atlassian/browser-metrics';

export const pageLoad = metrics.pageLoad({
	key: 'projects-directory-v3',
	ssr: {
		doneAsFmp: true,
	},
	featureFlags: ['projects-directory-entrypoint_sn3p0'],
});
