import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { AnyAri } from '@atlassian/ari/any-ari';
import { fg } from '@atlassian/jira-feature-gating';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import { useChangeFavorite } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { favouriteCell_directoryBaseV3_FavoriteCell_queryRef$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_FavoriteCell_queryRef.graphql';
import type { favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef.graphql';
import type { favouriteCell_directoryBaseV3_Mutation } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_Mutation.graphql';
import messages from './messages.tsx';

interface OldProps {
	id: string;
	favoriteItemName?: string | undefined;
	favouriteValueRef: favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef$key;
	onClick?: (data: { isFavourite: boolean }) => void;
}

type Props = {
	queryRef: favouriteCell_directoryBaseV3_FavoriteCell_queryRef$key;
	onClick: (data: { isFavourite: boolean }) => void;
};

export const FavoriteCell = ({ queryRef, onClick }: Props) => {
	const { changeFavoriteMutation } = useChangeFavorite();

	const { id, name, isFavourite } = useFragment(
		graphql`
			fragment favouriteCell_directoryBaseV3_FavoriteCell_queryRef on JiraProject {
				id
				name
				isFavourite
			}
		`,
		queryRef,
	);

	const handleClick = useCallback(() => {
		onClick?.({ isFavourite: !isFavourite });
		changeFavoriteMutation({ id, value: !isFavourite, typename: 'JiraProject' });
	}, [onClick, isFavourite, changeFavoriteMutation, id]);

	return (
		<ContentWrapper>
			<FavoriteButtonStateless
				onClick={handleClick}
				isShownInList
				isFavorite={isFavourite}
				favoriteItemName={name}
			/>
		</ContentWrapper>
	);
};

export const FavoriteCellOld = ({
	id: entityId,
	favoriteItemName,
	favouriteValueRef,
	onClick,
}: OldProps) => {
	const { showFlag } = useFlagsService();
	const { updateFavoriteState } = useChangeFavorite();
	const { __id: recordId, isFavourite } = useFragment(
		graphql`
			fragment favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef on JiraFavouriteValue {
				__id
				isFavourite
			}
		`,
		favouriteValueRef,
	);

	const [commit] = useMutation<favouriteCell_directoryBaseV3_Mutation>(graphql`
		mutation favouriteCell_directoryBaseV3_Mutation($entityId: ID!, $isFavourite: Boolean!) {
			jira {
				setEntityIsFavourite(input: { entityId: $entityId, isFavourite: $isFavourite })
					@optIn(to: "JiraFavourite") {
					success
				}
			}
		}
	`);

	const updateFavouriteRecord = useCallback(
		(store: RecordSourceSelectorProxy) => {
			const { resourceId, resourceType } = fg('camptasks-1102-enable-atlassian-ari-projects')
				? AnyAri.parse(entityId)
				: getAriConfig(entityId);
			const favouriteEntityRecord = store.get(recordId);

			if (resourceType === 'project') {
				updateFavoriteState({ id: resourceId, type: 'projects', value: !isFavourite }); // sync global context provider
			}
			favouriteEntityRecord?.setValue(!isFavourite, 'isFavourite');
		},
		[isFavourite, recordId, entityId, updateFavoriteState],
	);

	const handleClick = useCallback(() => {
		onClick &&
			onClick({
				isFavourite: !isFavourite,
			});

		commit({
			variables: {
				entityId,
				isFavourite: !isFavourite,
			},
			optimisticUpdater: updateFavouriteRecord,
			updater: updateFavouriteRecord,
			onCompleted: (data) => {
				if (!data?.jira?.setEntityIsFavourite?.success) {
					showFlag({
						type: 'error',
						title: messages.title,
						description: messages.description,
						messageId: 'directory-base-v3.ui.table-cells.favourite-cell.show-flag.error',
						messageType: 'transactional',
					});
				}
			},
		});
	}, [onClick, isFavourite, commit, entityId, updateFavouriteRecord, showFlag]);

	return (
		<ContentWrapper>
			<FavoriteButtonStateless
				onClick={handleClick}
				isShownInList
				isFavorite={isFavourite}
				favoriteItemName={favoriteItemName}
			/>
		</ContentWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
