import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import { CellWrapper } from '@atlassian/jira-directory-base-v3/src/ui/table-cells/styled.tsx';
import type { projectCategoryCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectCategoryCell_projectsDirectoryV3.graphql';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	project: projectCategoryCell_projectsDirectoryV3$key;
};

export const ProjectCategoryCell = ({ project }: Props) => {
	const data = useFragment(
		graphql`
			fragment projectCategoryCell_projectsDirectoryV3 on JiraProject {
				category {
					name
				}
			}
		`,
		project,
	);

	if (data?.category?.name && fg('blu-6080-directory-table-tooltips')) {
		return <Inline>{data?.category?.name}</Inline>;
	}

	return <CellWrapper>{data?.category?.name}</CellWrapper>;
};
