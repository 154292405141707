import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button/standard-button';
import EmptyState, { type EmptyStateProps } from '@atlaskit/empty-state';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import errorSubtleImageUrl from './assets/error-subtle.svg';
import errorImageUrl from './assets/error.svg';
import messages from './messages.tsx';

export type Message = MessageDescriptor;
type Props = {
	intl: IntlShape;
	header: string;
	description: ReactNode;
	size: 'wide' | 'narrow';
	isLoading: boolean;
	onRetry?: () => void;
	errorImage?: string;
	isSubtle: boolean;
	imageWidth?: number;
	retryMessage: MessageDescriptor;
	maxImageHeight?: number;
	renderErrorImage?: EmptyStateProps['renderImage'];
	headingLevel?: number;
};
/**
 * Renders an error state component with customizable options such as header, description,
 * and an optional retry action. It supports loading states and can display a default or
 * custom error image. This component is intended for use in error handling scenarios
 * where a clear message and potential recovery action need to be presented to the user.
 */
export function Error(props: Props) {
	const {
		header,
		description,
		size,
		onRetry,
		isLoading,
		intl: { formatMessage },
		errorImage,
		isSubtle,
		imageWidth,
		maxImageHeight,
		retryMessage,
		renderErrorImage,
		headingLevel,
	} = props;
	const buttonAppearance = isSubtle ? 'default' : 'primary';
	const defaultImageUrl = isSubtle ? errorSubtleImageUrl : errorImageUrl;

	const fallbackImage = errorImage || defaultImageUrl;

	let imageUrl;

	if (renderErrorImage) {
		imageUrl = undefined;
	} else {
		imageUrl = fallbackImage;
	}

	return (
		<EmptyState
			testId="common-components-error-state.empty-state"
			size={size}
			imageWidth={imageWidth}
			imageUrl={imageUrl}
			header={header}
			description={description}
			isLoading={isLoading}
			maxImageHeight={maxImageHeight}
			primaryAction={
				!onRetry ? null : (
					<Button appearance={buttonAppearance} isDisabled={isLoading} onClick={onRetry}>
						{formatMessage(retryMessage)}
					</Button>
				)
			}
			renderImage={renderErrorImage}
			headingLevel={headingLevel}
		/>
	);
}
Error.defaultProps = {
	header: '',
	size: 'wide' as const,
	isLoading: false,
	isSubtle: false,
	retryMessage: messages.retry,
};
export default injectIntl(Error);
