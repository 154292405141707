/**
 * @generated SignedSource<<4f107b1dd5aac0ef2fc16a6661a002b9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionsCell_projectsDirectoryV3_project$data = {
  readonly canViewProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_project">;
  readonly " $fragmentType": "actionsCell_projectsDirectoryV3_project";
};
export type actionsCell_projectsDirectoryV3_project$key = {
  readonly " $data"?: actionsCell_projectsDirectoryV3_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionsCell_projectsDirectoryV3_project",
  "selections": [
    {
      "alias": "canViewProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "VIEW_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
    },
    {
      "kind": "FragmentSpread",
      "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "c8748624e96975706ef2288b147d4354";

export default node;
