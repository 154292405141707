import React, { useMemo, type PropsWithChildren } from 'react';
import { css, styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import { type SortProps, type HeadType, type RowType, SortOrder } from './types.tsx';
import { TableHead } from './ui/table-head/index.tsx';
import { TableRow } from './ui/table-row/index.tsx';

export interface Props extends SortProps {
	head: HeadType;
	rows: RowType[];
	captionText?: string;
}

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SortOrder, SortProps, HeadType, RowType, OnSort } from './types';

export const SimpleTable = ({ head, rows, sortKey, sortOrder, onSort, captionText }: Props) => {
	const columnKeys = useMemo(() => head.cells.map((cell) => cell.key), [head]);
	const { formatMessage } = useIntl();

	const alertMessage =
		sortOrder === SortOrder.ASC ? messages.srOnlySortingAlertAsc : messages.srOnlySortingAlertDesc;

	return (
		<Table>
			{captionText ? (
				<SrOnlyCaption>{captionText}</SrOnlyCaption>
			) : (
				<SrOnlyCaption>
					{formatMessage(messages.srOnlyCaption)}
					{sortOrder && (
						// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
						<span aria-live="polite" aria-relevant="text">
							{formatMessage(alertMessage, {
								sortKey,
							})}
						</span>
					)}
				</SrOnlyCaption>
			)}
			<TableHead onSort={onSort} {...head} sortKey={sortKey} sortOrder={sortOrder} />
			<tbody>
				{rows.map((rowProps, index) => (
					<TableRow
						isLastRow={isVisualRefreshEnabled() ? index === rows.length - 1 : false}
						columnKeys={columnKeys}
						{...rowProps}
						key={`table-row-${index}`}
					/>
				))}
			</tbody>
		</Table>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableOld = styled.table({
	borderCollapse: 'collapse',
	width: '100%',
	tableLayout: 'fixed',
	marginTop: 0,
	marginRight: 0,
	marginBottom: token('space.300'),
	marginLeft: 0,
});

export const Table = componentWithCondition(
	() => isVisualRefreshEnabled(),
	({ children }: PropsWithChildren<{}>) => (
		<Box as="table" xcss={tableStyles}>
			{children}
		</Box>
	),
	({ children }: PropsWithChildren<{}>) => <TableOld>{children}</TableOld>,
);

const tableStyles = xcss({
	width: '100%',
	tableLayout: 'fixed',
	margin: 'space.0',
	marginBottom: 'space.300',
	border: `1px solid ${token('color.border')}`,
	borderRadius: 'border.radius.200',
	borderCollapse: 'separate',
	borderSpacing: 0,
	overflow: 'hidden',
});

const srOnlyStyles = css({
	position: 'absolute',
	top: 'auto',
	overflow: 'hidden',
	clip: 'rect(1px, 1px, 1px, 1px)',
	width: '1px',
	height: '1px',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const SrOnlyCaption = styled.caption(srOnlyStyles);
