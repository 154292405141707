import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import {
	graphql,
	usePreloadedQuery,
	useRefetchableFragment,
	type PreloadedQuery,
} from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useDirectoryRefetch } from '@atlassian/jira-directory-base-v3/src/controllers/use-directory-refetch/index.tsx';
import { useQueryFilter } from '@atlassian/jira-directory-base-v3/src/controllers/use-query-filter/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { ExperienceSuccess as PlatformExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	PROJECTS_DIRECTORY_EXPERIENCE,
	MANAGE_PROJECTS_DIRECTORY_EXPERIENCE,
	DEFAULT_LIMIT,
	FilterFields,
	type FilterInitialState as FilterState,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import { mapQueryToFilter } from '@atlassian/jira-projects-directory-v3-utils/src/utils/index.tsx';
import { pageLoad } from '@atlassian/jira-projects-directory-v3-utils/src/utils/performance-analytics.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery.graphql';
import type { pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$key } from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryV3_ProjectDirectoryLayout.graphql';
import RefetchProjectsQuery, {
	type ProjectDirectoryRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/ProjectDirectoryRefetchQuery.graphql';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { ScrollablePage } from '@atlassian/jira-spa-apps-page-layout/src/page/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { FilterContainer } from '../../controllers/filter-state/index.tsx';
import { AsyncEmptyView } from './empty-view/async.tsx';
import { PageErrorState } from './error-state/index.tsx';
import { Header } from './header/index.tsx';
import messages from './messages.tsx';
import { AsyncNoPermissionViews } from './no-permission-views/async.tsx';
import { ProjectsList } from './projects-list/index.tsx';
import { RefinementBar } from './refinement-bar/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PageErrorState } from './error-state';

type Props = {
	queryRef: PreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>;
	isAdminSettingsContext: boolean;
};

export const ProjectDirectoryLayout = ({ queryRef, isAdminSettingsContext }: Props) => {
	const experienceKey = isAdminSettingsContext
		? MANAGE_PROJECTS_DIRECTORY_EXPERIENCE
		: PROJECTS_DIRECTORY_EXPERIENCE;
	const cloudId = useCloudId();
	const syncQueryFilter = useQueryFilter();
	const onFail = useExperienceFail({
		experience: experienceKey,
	});
	const { formatMessage } = useIntl();

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const rootQueryRef = usePreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>(
		graphql`
			query pageLayout_projectsDirectoryLayout_RootQuery(
				$cloudId: ID!
				$filter: JiraProjectFilterInput!
				$first: Int
				$last: Int
				$isAdminSettingsContext: Boolean!
				$isAnonymous: Boolean!
			) @preloadable {
				...header_projectsDirectoryV3 @arguments(isAnonymous: $isAnonymous)
				...refinementBar_projectsDirectoryV3_Filters @arguments(isAnonymous: $isAnonymous)
				...projectsList_projectsDirectoryV3_rootQueryRef @arguments(isAnonymous: $isAnonymous)
				...pageLayout_projectsDirectoryV3_ProjectDirectoryLayout
					@arguments(isAdminSettingsContext: $isAdminSettingsContext)
				...emptyView_projectsDirectoryV3
				...noPermissionViews_projectsDirectoryV3
				jira {
					canBrowseProjects: permission(cloudId: $cloudId, type: BROWSE_PROJECTS) {
						hasPermission
					}
				}
			}
		`,
		queryRef,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	const [dataRef, refetch] = useRefetchableFragment<
		ProjectDirectoryRefetchQuery,
		pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$key
	>(
		graphql`
			fragment pageLayout_projectsDirectoryV3_ProjectDirectoryLayout on Query
			@argumentDefinitions(isAdminSettingsContext: { type: "Boolean!" })
			@refetchable(queryName: "ProjectDirectoryRefetchQuery") {
				jira {
					allJiraProjects(cloudId: $cloudId, filter: $filter, first: $first, last: $last) {
						...projectsList_projectsDirectoryV3_projectsRef
							@arguments(isAdminSettingsContext: $isAdminSettingsContext)
						totalCount
					}
				}
			}
		`,
		rootQueryRef,
	);

	const prevDataRef = usePrevious(dataRef);

	const [{ isFetching, errors }, { refetchData }] = useDirectoryRefetch({
		refetch,
		query: RefetchProjectsQuery,
	});

	const handleFilterUpdate = useCallback(
		(filter: FilterState) => {
			const { [FilterFields.Page]: currentPage } = filter;

			refetchData({
				variables: {
					cloudId,
					filter: mapQueryToFilter(filter, { cloudId }),
					first: DEFAULT_LIMIT * parseInt(currentPage, 10),
					last: DEFAULT_LIMIT,
					isAdminSettingsContext,
				},
				options: { fetchPolicy: 'network-only' },
			});
			syncQueryFilter(filter);
		},
		[cloudId, refetchData, syncQueryFilter, isAdminSettingsContext],
	);

	const isSiteAdmin = useIsSiteAdmin();
	const isJiraAdmin = useIsAdmin();

	const canBrowseProjects =
		isSiteAdmin || isJiraAdmin || rootQueryRef?.jira?.canBrowseProjects?.hasPermission;

	if (!canBrowseProjects) {
		return (
			<HeaderlessContainer>
				<AsyncNoPermissionViews dataRef={rootQueryRef} />
				<PlatformExperienceSuccess
					experience={experienceKey}
					attributes={{ view: 'no-permission' }}
				/>
			</HeaderlessContainer>
		);
	}

	if (errors) {
		onFail('projects-directory-v3.page', errors);
		return (
			<HeaderlessContainer>
				<PageErrorState />
			</HeaderlessContainer>
		);
	}

	return fg('blu-6146-responsive-directories') ? (
		<JSErrorBoundary id="page-layout" packageName="jiraProjectsDirectoryV3" onError={onFail}>
			<FilterContainer isGlobal onUpdate={handleFilterUpdate}>
				<Box paddingInline={isVisualRefreshEnabled() ? 'space.300' : 'space.500'}>
					<DocumentTitle
						title={
							isAdminSettingsContext
								? formatMessage(messages.adminDocumentTitle)
								: formatMessage(messages.documentTitle)
						}
					/>
					<Header dataRef={rootQueryRef} isAdminSettingsContext={isAdminSettingsContext} />
					<RefinementBar dataRef={rootQueryRef} />
				</Box>
				<ScrollablePage>
					<AnnouncerV2
						message={formatMessage(messages.filterResultsAnnouncement, {
							itemCount: dataRef?.jira?.allJiraProjects?.totalCount || 0,
						})}
						shouldAnnounce={!isFetching && prevDataRef !== dataRef}
					/>

					{dataRef?.jira?.allJiraProjects && dataRef?.jira?.allJiraProjects?.totalCount ? (
						<>
							<ProjectsList
								isLoading={isFetching}
								isAdminSettingsContext={isAdminSettingsContext}
								projectsRef={dataRef?.jira?.allJiraProjects}
								rootQueryRef={rootQueryRef}
							/>
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'projects-list' }}
							/>
						</>
					) : (
						<>
							<AsyncEmptyView dataRef={rootQueryRef} />
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'empty' }}
							/>
						</>
					)}
				</ScrollablePage>
			</FilterContainer>
			<SpaStatePageReady />
			<SubmitApdex
				appName="projects-directory-v3"
				metricKey="jira.directories.projects-v3"
				isExpectedToHaveSsr
				// @ts-expect-error - TS2322
				metric={pageLoad}
			/>
			<SubProductUpdater subProduct="platform" />
		</JSErrorBoundary>
	) : (
		<JSErrorBoundary id="page-layout" packageName="jiraProjectsDirectoryV3" onError={onFail}>
			<ScrollablePage>
				<FilterContainer isGlobal onUpdate={handleFilterUpdate}>
					<DocumentTitle
						title={
							isAdminSettingsContext
								? formatMessage(messages.adminDocumentTitle)
								: formatMessage(messages.documentTitle)
						}
					/>
					<Header dataRef={rootQueryRef} isAdminSettingsContext={isAdminSettingsContext} />

					<RefinementBar dataRef={rootQueryRef} />

					<AnnouncerV2
						message={formatMessage(messages.filterResultsAnnouncement, {
							itemCount: dataRef?.jira?.allJiraProjects?.totalCount || 0,
						})}
						shouldAnnounce={!isFetching && prevDataRef !== dataRef}
					/>

					{dataRef?.jira?.allJiraProjects && dataRef?.jira?.allJiraProjects?.totalCount ? (
						<>
							<ProjectsList
								isLoading={isFetching}
								isAdminSettingsContext={isAdminSettingsContext}
								projectsRef={dataRef?.jira?.allJiraProjects}
								rootQueryRef={rootQueryRef}
							/>
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'projects-list' }}
							/>
						</>
					) : (
						<>
							<AsyncEmptyView dataRef={rootQueryRef} />
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'empty' }}
							/>
						</>
					)}
				</FilterContainer>
			</ScrollablePage>
			<SpaStatePageReady />
			<SubmitApdex
				appName="projects-directory-v3"
				metricKey="jira.directories.projects-v3"
				isExpectedToHaveSsr
				// @ts-expect-error - TS2322
				metric={pageLoad}
			/>
			<SubProductUpdater subProduct="platform" />
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderlessContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'padding-top': `${gridSize * 8}px`,
});
