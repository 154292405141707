import React, { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export type ScrollablePageProps = PropsWithChildren<{ 'data-testid'?: string }>;

export const ScrollablePage = ({ children, ...rest }: ScrollablePageProps) => (
	<Box
		testId={rest['data-testid'] || 'spa-apps-page-layout.page.scrollable-page'}
		xcss={scrollablePageStyles}
		paddingBlock="space.0"
		paddingInline={isVisualRefreshEnabled() ? 'space.300' : 'space.500'}
	>
		{children}
	</Box>
);

export type PageLayoutProps = PropsWithChildren<{ hideMargin?: boolean; 'data-testid'?: string }>;

export const PageLayout = ({ children, hideMargin, ...rest }: PageLayoutProps) => {
	const isVisualRefresh = isVisualRefreshEnabled();
	return (
		<Box
			testId={rest['data-testid'] || 'spa-apps-page-layout.page.page-layout'}
			xcss={
				// eslint-disable-next-line no-nested-ternary
				hideMargin
					? pageLayoutWithoutMarginStyles
					: isVisualRefresh
						? pageLayoutWithMarginStyles
						: pageLayoutWithMarginStylesOld
			}
		>
			{children}
		</Box>
	);
};

const scrollablePageStyles = xcss({
	minWidth: '800px', // (gridSize * 90) + ((gridSize * 5) * 2)
	margin: 'space.0',
});

const pageLayoutWithoutMarginStyles = xcss({
	margin: 'space.0',
});

const pageLayoutWithMarginStylesOld = xcss({
	marginBlock: '0',
	marginInline: 'space.500',
});

const pageLayoutWithMarginStyles = xcss({
	marginBlock: '0',
	marginInline: 'space.300',
});
