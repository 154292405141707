import React, { useMemo, type ReactNode, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import ArchiveBoxIcon from '@atlaskit/icon/core/archive-box';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import messages from './messages.tsx';
import {
	hasPermissionGate,
	hasPermissionToArchive,
	isFreeEdition,
	isStandardEdition,
} from './utils.tsx';

type ShouldShowArchiveProjectTriggerArgs = {
	isAdmin: boolean;
	isSimplified: boolean;
	canAdministerProject: boolean;
	projectType: ProjectType;
	shouldHideFeatureGateTrigger?: boolean;
	isCorePremiumUserSeat: boolean;
};

type ShouldShowArchiveProjectTriggerResult = { showPremiumLozenge: boolean } | false;

export function useShouldShowArchiveProjectTrigger({
	isAdmin,
	isSimplified,
	canAdministerProject,
	projectType,
	shouldHideFeatureGateTrigger,
	isCorePremiumUserSeat,
}: ShouldShowArchiveProjectTriggerArgs): ShouldShowArchiveProjectTriggerResult {
	const appEditions = useAppEditions();

	if (!hasPermissionToArchive({ projectType, isSimplified, isAdmin, canAdministerProject })) {
		return false;
	}

	const isJswPremiumEdition = hasPermissionGate(SOFTWARE_PROJECT, appEditions);
	const isJswStandardEdition = isStandardEdition(SOFTWARE_PROJECT, appEditions);
	const isJswFreeEdition = isFreeEdition(SOFTWARE_PROJECT, appEditions);
	const isJsmPremiumEdition = hasPermissionGate(SERVICE_DESK_PROJECT, appEditions);

	if (
		(projectType === SOFTWARE_PROJECT && isJswPremiumEdition) ||
		(projectType === SERVICE_DESK_PROJECT && isJsmPremiumEdition)
	) {
		return { showPremiumLozenge: false };
	}

	// Standard or Free Edition admins should see the Project archiving FG for JSW and Core Projects
	if (
		isAdmin &&
		!shouldHideFeatureGateTrigger &&
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		(isJswStandardEdition || isJswFreeEdition)
	) {
		return { showPremiumLozenge: true };
	}

	// Enable Project Archiving feature for Core Projects if either Software, Service Desk or Core are Premium
	if (projectType === CORE_PROJECT && isCorePremiumUserSeat) {
		return { showPremiumLozenge: false };
	}

	return false;
}

export type Props = ShouldShowArchiveProjectTriggerArgs & {
	Component?: ComponentType<{
		children: ReactNode;
	}>;
	onClick: () => void;
	customLabelText?: string;
};

export const ProjectArchiveTrigger = (props: Props) => {
	const {
		projectType,
		onClick,
		isAdmin,
		canAdministerProject,
		isSimplified,
		Component,
		isCorePremiumUserSeat,
		shouldHideFeatureGateTrigger,
		customLabelText,
	} = props;

	const { formatMessage } = useIntl();

	const premiumLozenge = useMemo(
		() => (
			<LozengeWrapper>
				<Lozenge appearance="new">{formatMessage(messages.premiumLozenge)}</Lozenge>
			</LozengeWrapper>
		),
		[formatMessage],
	);

	const getDropdownItem = ({ showPremiumLozenge }: { showPremiumLozenge: boolean }) => {
		const dropdownChildren = (
			<>
				{customLabelText ||
					formatMessage(
						projectType === SERVICE_DESK_PROJECT
							? messages.archiveTriggerServiceManagement
							: messages.archiveTrigger,
					)}
				{showPremiumLozenge && premiumLozenge}
			</>
		);

		return Component ? (
			<Component>{dropdownChildren}</Component>
		) : (
			<DropdownItem
				elemBefore={
					fg('jira_custom_templates_ux') && (
						<ArchiveBoxIcon
							testId="project-archive-trigger.ui.archive-icon"
							label=""
							spacing="spacious"
							color={token('color.icon')}
						/>
					)
				}
				onClick={onClick}
			>
				{dropdownChildren}
			</DropdownItem>
		);
	};

	const shouldShowResult = useShouldShowArchiveProjectTrigger({
		isAdmin,
		isSimplified,
		canAdministerProject,
		projectType,
		shouldHideFeatureGateTrigger,
		isCorePremiumUserSeat,
	});

	if (!shouldShowResult) {
		return null;
	}
	return getDropdownItem(shouldShowResult);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'inline',
	marginLeft: token('space.050'),
});
