import React, { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

type FilterItemProps = PropsWithChildren & {
	/** Whether the filter item should expand to take up more space when the parent container is narrow */
	expandOnSmall?: boolean;
};

export const FilterItem = ({ expandOnSmall, children }: FilterItemProps) => {
	return (
		<Box
			xcss={
				expandOnSmall
					? [filterItemContainerStyles, filterItemContainerExpandedStyles]
					: filterItemContainerStyles
			}
		>
			{children}
		</Box>
	);
};

const filterItemContainerStyles = xcss({
	// 200px is the width commonly used for directory filters, so including it here helps
	// to ensure consistency with the existing design and gives us predictability for responsive behaviour
	minWidth: '200px',
});

const filterItemContainerExpandedStyles = xcss({
	gridColumn: '1 / -1', // span all columns without needing to know how many there are
});
